import { $PropertyType } from "utility-types";
import { prop } from "lodash/fp";
import type { Dispatch } from "redux";
import type { Action } from "storefront/Action";
import type { Id } from "storefront/lib/Id";
import type { Shipment } from "storefront/Shipment";
import type { Conversations } from "storefront/Conversations";
import type { Conversation } from "storefront/Conversation";
import type { Metadata } from "storefront/GrailedAPI/v1/Conversations/findById";
import timeout from "storefront/lib/timeout";
import {
  TransactionPaymentStatus,
  ALL_PARTIES_FULLY_REFUNDED,
} from "storefront/Transactions";
import { activityAdded } from "storefront/ReduxActions/Conversation/ActivityAdded";
import type { ActivityAddedResponse } from "storefront/ReduxActions/Conversation/ActivityAdded";
import { offerModalClosed } from "storefront/ReduxActions/Conversation/OfferModalClosed";
import { replyModalClosed } from "storefront/ReduxActions/Conversation/ReplyModalClosed";
import findConversationById from "storefront/GrailedAPI/v1/Conversations/findById";
import { CREATE_SHIPMENT_SUCCESS } from "storefront/ReduxActions/Shipment/CreateShipment";
import FlashManager from "storefront/lib/flash/FlashManager";
import { EDIT_SHIPMENT_SUCCESS } from "storefront/ReduxActions/Shipment/EditShipment";
import getLightConversations from "storefront/GrailedAPI/v1/Conversations/getLightConversations";
import GrailedAPIError from "storefront/GrailedAPI/Error";
import {
  FETCH_CONVERSATIONS_REQUEST,
  FETCH_CONVERSATIONS_SUCCESS,
  FETCH_CONVERSATIONS_ERROR,
  FETCH_CONVERSATION_REQUEST,
  FETCH_CONVERSATION_SUCCESS,
  FETCH_CONVERSATION_ERROR,
  FOCUS_CONVERSATION,
  UNFOCUS_CONVERSATION,
  ACCEPT_OFFER_REQUEST,
  ACCEPT_OFFER_SUCCESS,
  ACCEPT_OFFER_ERROR,
  CONVERSATION_EXPAND_ACTIVITY_LOG,
  CONVERSATION_FINISHED_SLIDING_DOWN_ACTIVITY_LOG,
  MARK_CONVERSATION_AS_READ_SUCCESS,
  SET_CONVERSATION_CONTEXT,
  EXPAND_CONVERSATION_SETTINGS,
  HIDE_CONVERSATION_SETTINGS,
  OPEN_OFFER_MODAL,
  OPEN_REPLY_MODAL,
} from "../constants/action_types";
import GrailedAPI from "../lib/grailed_api";

type ConversationContext = $PropertyType<Conversations, "context">;

export function fetchConversationsRequest() {
  return {
    type: FETCH_CONVERSATIONS_REQUEST,
    payload: {},
  };
}

export function fetchConversationRequest(conversationId: Id) {
  return {
    type: FETCH_CONVERSATION_REQUEST,
    payload: {
      conversationId,
    },
  };
}

export function fetchConversationSuccess(
  data: Conversation,
  metadata: Metadata,
) {
  return {
    type: FETCH_CONVERSATION_SUCCESS,
    payload: {
      data,
      metadata,
    },
  };
}

export function fetchConversationsSuccess(
  response: Record<string, any>,
  context: ConversationContext,
  page: number,
) {
  return {
    type: FETCH_CONVERSATIONS_SUCCESS,
    payload: {
      response,
      context,
      page,
    },
  };
}

export function fetchConversationsError(error: Error) {
  return {
    type: FETCH_CONVERSATIONS_ERROR,
    payload: {
      error,
    },
  };
}

export function fetchConversationError(conversationId: Id, error: Error) {
  return {
    type: FETCH_CONVERSATION_ERROR,
    payload: {
      conversationId,
      error,
    },
  };
}

export function acceptOfferRequest() {
  return {
    type: ACCEPT_OFFER_REQUEST,
    payload: {},
  };
}

export function acceptOfferSuccess() {
  return {
    type: ACCEPT_OFFER_SUCCESS,
    payload: {},
  };
}

export function acceptOfferError() {
  return {
    type: ACCEPT_OFFER_ERROR,
    payload: {},
  };
}

export function expandConversationActivityLog(conversationId: Id) {
  return {
    type: CONVERSATION_EXPAND_ACTIVITY_LOG,
    payload: {
      conversationId,
    },
  };
}

export function finishedSlidingDownConversation(conversationId: Id) {
  return {
    type: CONVERSATION_FINISHED_SLIDING_DOWN_ACTIVITY_LOG,
    payload: {
      conversationId,
    },
  };
}

export function markConversationAsReadSuccess(id: Id) {
  return {
    type: MARK_CONVERSATION_AS_READ_SUCCESS,
    payload: {
      id,
    },
  };
}

// NOTE: There are a bunch of places that still use this funciton in such a way that we need to
// export it from this file. Search the codebase for "actions.addActionToConversation" to find
// those places.
// [Evan 2021-03-23]
export const addActionToConversation = activityAdded;

export function clickedOnConversation(id: Id) {
  return (
    dispatch: (...args: Array<any>) => any,
    getState: (...args: Array<any>) => any,
  ) => {
    const state = getState().conversations;
    const conversation = state[state.context].conversations.find(
      (c: Conversation) => c.id === id,
    );

    if (!conversation.isRead) {
      dispatch(markConversationAsRead(id));
    }

    if (conversation.isLight) {
      dispatch(fetchAndFocusConversation(id));
    } else {
      dispatch(focusConversation(id));
    }
  };
}

export function markConversationAsRead(id: Id) {
  return (dispatch: (...args: Array<any>) => any) => {
    GrailedAPI.conversations
      .markAsRead(id)
      .then(dispatch(markConversationAsReadSuccess(id)));
  };
}

export function acceptOffer(
  listingId: Id,
  amount: number,
  conversationId: Id,
  offerType: string,
) {
  return (dispatch: (...args: Array<any>) => any) => {
    dispatch(acceptOfferRequest());

    // Accept Binding Offer
    if (offerType === "binding") {
      FlashManager.getInstance().notice("Accepting offer...");
      GrailedAPI.offers
        .acceptBindingOffer(listingId, amount, conversationId)
        .then((data: ActivityAddedResponse) => {
          dispatch(acceptOfferSuccess());
          dispatch(activityAdded(data));
          FlashManager.getInstance().notice(
            "Offer accepted. We are processing the buyer's payment...",
          );
        })
        .catch((err: GrailedAPIError) => {
          dispatch(acceptOfferError());
          dispatch(fetchAndFocusConversation(conversationId));
          FlashManager.getInstance().alert(
            prop("body.error.message", err) ||
              "There was an error while accepting the offer. Please try again.",
          );
        });

      // Accept NonBinding Offer
    } else {
      GrailedAPI.offers
        .accept(listingId, amount, conversationId)
        .then((data: ActivityAddedResponse) => {
          dispatch(acceptOfferSuccess());
          dispatch(activityAdded(data));
          FlashManager.getInstance().notice(
            "Offer accepted. We've sent the buyer a link to pay via PayPal. The purchase is not complete until the buyer sends payment.",
          );
        })
        .catch((err: GrailedAPIError) => {
          dispatch(acceptOfferError());
          FlashManager.getInstance().alert(
            prop("body.error.message", err) ||
              "There was an error while accepting the offer. Please try again.",
          );
        });
    }
  };
}

export function fetchAndFocusConversation(conversationId: Id) {
  return (dispatch: Dispatch<Action>) => {
    dispatch(fetchConversationRequest(conversationId));
    timeout(findConversationById(conversationId))
      .then(([conversation, metadata]) =>
        dispatch(fetchConversationSuccess(conversation, metadata)),
      )
      .catch((error) => {
        if (error.message === "TIMEOUT") {
          return dispatch(fetchConversationError(conversationId, error));
        }

        throw error;
      });
  };
}

export function fetchConversationAndMarkListingAsRefunded(
  conversationId: Id,
  status: TransactionPaymentStatus,
) {
  return (dispatch: Dispatch<Action>) => {
    dispatch(fetchConversationRequest(conversationId));
    timeout(findConversationById(conversationId))
      .then(([conversation, metadata]) =>
        dispatch(
          fetchConversationSuccess(
            {
              ...conversation,
              listing: {
                ...conversation.listing,
                deleted: status === ALL_PARTIES_FULLY_REFUNDED,
                deletedViaRefund: status === ALL_PARTIES_FULLY_REFUNDED,
                paymentStatus: status,
              },
            },
            metadata,
          ),
        ),
      )
      .catch((error) => {
        if (error.message === "TIMEOUT") {
          return dispatch(fetchConversationError(conversationId, error));
        }

        throw error;
      });
  };
}

export function fetchConversations(context: ConversationContext) {
  return (
    dispatch: (...args: Array<any>) => any,
    getState: (...args: Array<any>) => any,
  ) => {
    const state = getState().conversations;
    const pageNumber = state[context].nextPage;
    dispatch(fetchConversationsRequest());
    timeout(getLightConversations(pageNumber, context, state.isViewingArchived))
      .then((data) =>
        dispatch(fetchConversationsSuccess(data, context, pageNumber)),
      )
      .catch((error) => {
        if (error.message === "TIMEOUT") {
          return dispatch(fetchConversationsError(error));
        }

        throw error;
      });
  };
}

export function fetchMoreConversations() {
  return (
    dispatch: (...args: Array<any>) => any,
    getState: (...args: Array<any>) => any,
  ) => {
    const state = getState().conversations;
    const { nextPage } = state[state.context];
    dispatch(fetchConversationsRequest());
    timeout(
      getLightConversations(nextPage, state.context, state.isViewingArchived),
    )
      .then((data) =>
        dispatch(fetchConversationsSuccess(data, state.context, nextPage)),
      )
      .catch((error) => {
        if (error.message === "TIMEOUT") {
          return dispatch(fetchConversationsError(error));
        }

        throw error;
      });
  };
}

export function unfocusConversation(unfocusedConversationId: Id) {
  return {
    type: UNFOCUS_CONVERSATION,
    payload: {
      unfocusedConversationId,
    },
  };
}

export function focusConversation(activeConversationId: Id) {
  return {
    type: FOCUS_CONVERSATION,
    payload: {
      activeConversationId,
    },
  };
}

export function setConversationContext(context: ConversationContext) {
  return {
    type: SET_CONVERSATION_CONTEXT,
    payload: {
      context,
    },
  };
}

export const expandConversationSettings = (conversationId: Id) => ({
  type: EXPAND_CONVERSATION_SETTINGS,
  payload: {
    conversationId,
  },
});

export const hideConversationSettings = () => ({
  type: HIDE_CONVERSATION_SETTINGS,
  payload: {},
});

export function openOfferModal(conversationId: number) {
  return {
    type: OPEN_OFFER_MODAL,
    payload: {
      conversationId,
    },
  };
}

// NOTE: There are a bunch of places that still use this funciton in such a way that we need to
// export it from this file. Search the codebase for "actions.closeOfferModal" to find those places.
// [Evan 2021-03-23]
export const closeOfferModal = offerModalClosed;

export function openReplyModal(conversationId: Id) {
  return {
    type: OPEN_REPLY_MODAL,
    payload: {
      conversationId,
    },
  };
}

// NOTE: There are a bunch of places that still use this funciton in such a way that we need to
// export it from this file. Search the codebase for "actions.closeReplyModal" to find those places.
// [Evan 2021-03-23]
export const closeReplyModal = replyModalClosed;

export function createShipmentSuccess(shipment: Shipment, conversationId: Id) {
  return {
    type: CREATE_SHIPMENT_SUCCESS,
    payload: {
      shipment,
      conversationId,
    },
  };
}

export function editShipmentSuccess(
  oldShipmentId: Id,
  shipment: Shipment,
  conversationId: Id,
) {
  return {
    type: EDIT_SHIPMENT_SUCCESS,
    payload: {
      oldShipmentId,
      shipment,
      conversationId,
    },
  };
}
