import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";
import Loading from "storefront/components/Loading";
import GrailedRoute from "./GrailedRoute";
import DesignersApp from "../../containers/designers_app";

const LazyEditListingForm = lazy(
  () =>
    import(
      /* webpackChunkName: "EditListing" */
      "../../components/sellform/EditListing"
    ),
);
const LazyEditListingDraftForm = lazy(
  () =>
    import(
      /* webpackChunkName: "EditListingDraftForm" */
      "../../components/sellform/EditListingDraft"
    ),
);

const BrowserRouterInner = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();

  useEffect(() => {
    if (window.RISKX) window.RISKX.go(location.pathname);
  }, [location]);

  return children;
};

const Router = () => {
  return (
    <BrowserRouter>
      <BrowserRouterInner>
        <Suspense fallback={<Loading message="Getting Started" />}>
          <Switch>
            <GrailedRoute
              path="/drycleanonly/matthew-williams"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "AlyxTimeline" */
                    "../../components/Marketing/AlyxTimeline"
                  ),
              )}
            />
            <Route
              path={["/404", "/internal", "/drycleanonly", "/the-editorial"]}
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "NotFoundPage" */
                    "./NotFoundPage"
                  ),
              )}
            />
            <Route
              exact
              path="/listings/:id"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "NotFoundPage" */
                    "./NotFoundPage"
                  ),
              )}
            />
            <Route
              path="/500"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "ServerErrorPage" */
                    "./ServerErrorPage"
                  ),
              )}
            />
            <Route
              exact
              path="/foryou"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "ForYouPage" */
                    "../../components/ForYouPage"
                  ),
              )}
            />
            <Route
              path="/admins/gateway"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "Gateway" */
                    "../../components/Admins/Gateway"
                  ),
              )}
            />
            <Route
              path="/users/sign_up"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "UserSessionFormApp" */
                    "../../containers/UserSessionFormApp"
                  ),
              )}
            />
            <Route
              path="/connect/paypal"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "pay_pal_connect_app" */
                    "../../containers/pay_pal_connect_app"
                  ),
              )}
            />
            <Route
              exact
              path="/messages"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "conversations_app" */
                    "../../containers/conversations_app"
                  ),
              )}
            />
            <Route
              exact
              path="/messages/:id"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "conversations_app" */
                    "../../containers/conversations_app"
                  ),
              )}
            />
            <Route
              path="/mygrails"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "FittingRoomApp" */
                    "../../containers/FittingRoomApp"
                  ),
              )}
            />
            <Route
              path="/users/payments"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "user_settings_app" */
                    "../../containers/user_settings_app"
                  ),
              )}
            />
            <Route
              exact
              path="/sell/new"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "NewListing" */
                    "../../components/sellform/NewListing"
                  ),
              )}
            />
            <Route
              path="/listings/:id/edit"
              render={(renderProps) => (
                <LazyEditListingForm listingId={renderProps.match.params.id} />
              )}
            />
            <Route
              path="/drafts/:id/edit"
              render={(renderProps) => (
                <LazyEditListingDraftForm
                  draftId={renderProps.match.params.id}
                />
              )}
            />
            <GrailedRoute
              exact
              path="/100(-aw2018)?"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "one_hundred_aw_2018" */
                    "../../components/Marketing/one_hundred_aw_2018"
                  ),
              )}
            />
            <GrailedRoute
              exact
              path="/100(-aw2018)?/lookbook"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "lookbook" */
                    "../../components/Marketing/one_hundred_aw_2018/lookbook"
                  ),
              )}
            />
            <GrailedRoute
              path="/promo/:slug"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "Advertising" */
                    "../../components/Marketing/Advertising"
                  ),
              )}
            />
            <Route
              path={["/shop", "/sold", "/feed", "/search"]}
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "ShopPage" */
                    "../../components/ShopPage"
                  ),
              )}
            />
            <Route exact path="/designers" component={DesignersApp} />
            <Route
              exact
              path="/users/purchases"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "PurchasesPage" */
                    "../../components/PurchasesPage"
                  ),
              )}
            />
            <Route
              path="/users/notifications"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "NotificationsAccountPage" */
                    "../../components/NotificationsAccountPage"
                  ),
              )}
            />
            <Route
              path="/users/mysizes"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "MySizesAccountPage" */
                    "../../components/MySizesAccountPage"
                  ),
              )}
            />
            <Route
              path="/users/myaddresses"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "MyAddressesAccountPage" */
                    "../../components/MyAddressesAccountPage"
                  ),
              )}
            />
            <Route
              path="/users/devices"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "DevicesAccountPage" */
                    "../../components/DevicesAccountPage"
                  ),
              )}
            />
            <Route
              path="/users/security"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "SecurityAccountPage" */
                    "../../components/SecurityAccountPage"
                  ),
              )}
            />
            <Route
              path="/how-to-sell"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "HowToSellGuidePage" */
                    "storefront/components/HowToSellGuidePage"
                  ),
              )}
            />
            <Route
              exact
              path="/collections"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "CapsulesIndexPage" */
                    "storefront/components/CapsulesIndexPage"
                  ),
              )}
            />
            <Route
              path="/collections/:slug"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "CapsulePage" */
                    "../../components/CapsulePage"
                  ),
              )}
            />
            <GrailedRoute
              path={["/categories/:department/:slug", "/categories/:slug"]}
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "CategoryPage" */
                    "../../components/CategoryPage"
                  ),
              )}
            />
            <Route
              path="/collaborations/:slug"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "DesignerCollaborationPage" */
                    "../../components/DesignerCollaborationPage"
                  ),
              )}
            />
            <Route
              path="/users/paypal-signup"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "PaypalSignup" */
                    "../../components/users/PaypalSignup"
                  ),
              )}
            />
            <Route
              path="/trust/seller-verification"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "trust_sellers_page" */
                    "../../components/TrustMicrosite/SellersPage"
                  ),
              )}
            />
            <Route
              path="/trust/purchase-protection"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "trust_protection_page" */
                    "../../components/TrustMicrosite/ProtectionPage"
                  ),
              )}
            />
            <Route
              path="/trust/authentication"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "trust_authentication_page" */
                    "../../components/TrustMicrosite/AuthenticationPage"
                  ),
              )}
            />
            <Route
              path="/trust"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "trust_home_page" */
                    "../../components/TrustMicrosite/HomePage"
                  ),
              )}
            />
            <Route
              path="/:username/feedback"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "feedback_app" */
                    "../../containers/feedback_app"
                  ),
              )}
            />
            <Route
              path="/wasnt-me"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "WasntMe" */
                    "storefront/components/WasntMe"
                  ),
              )}
            />
            <Route
              path="/users/myprofile"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "ProfilePage" */
                    "../../components/users/ProfilePage"
                  ),
              )}
            />
            <Route
              path="/:username"
              component={lazy(
                () =>
                  import(
                    /* webpackChunkName: "wardrobe_app" */
                    "../../containers/wardrobe_app"
                  ),
              )}
            />
          </Switch>
        </Suspense>
      </BrowserRouterInner>
    </BrowserRouter>
  );
};

export default Router;
