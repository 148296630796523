import type { Conversation } from "storefront/Conversation";

export const ACTIVITY_ADDED: "Conversation.ActivityAdded" =
  "Conversation.ActivityAdded";

export type ActivityAdded = {
  type: typeof ACTIVITY_ADDED;
  payload: {
    conversation: Conversation;
  };
};

export type ActivityAddedResponse = {
  data: {
    conversation: Conversation;
  };
};

export const activityAdded = (
  response: ActivityAddedResponse,
): ActivityAdded => ({
  type: ACTIVITY_ADDED,
  payload: {
    conversation: response.data.conversation,
  },
});
